export const EXPERIMENTS = {
  SHOW_ADD_COUPON_LABEL: 'specs.membership.ShowAddCouponLabel',
  USE_BLOCKS_SINGLE_PLAN_WIDGET: 'specs.membership.UseBlocksSinglePlanWidget',
  USE_BLOCKS_PACKAGE_PICKER_PAGE: 'specs.membership.UseBlocksPackagePickerPage',
  ALERT_MODALS: 'specs.membership.AlertModals',
  USE_WARMUP_DATA: 'specs.membership.UseWarmupData',
  FORCE_MEMBERS_AREA_INSTALL: 'specs.membership.ForceMemberAreaInstall',
  FORCE_MEMBERS_AREA_NO_INSTALL: 'specs.membership.ForceMemberAreaNoInstall',
  SCROLL_INTO_CHECKOUT: 'specs.membership.ScrollIntoCheckout',
  DO_NOT_COLLAPSE_PAYMENTS_WIDGET: 'specs.membership.DoNotCollapsePaymentsWidget',
  PRICE_FORMATTER: 'specs.membership.PriceFormatter',
  ACCEPT_PAYMENTS_ERROR: 'specs.membership.AcceptPaymentsError',
  DEMO_PLANS_TRANSLATED: 'specs.membership.demoPlansTranslated',
  BENEFITS_ALIGNMENT: 'specs.membership.BenefitsAlignment',
  BLOCKS_LAYOUT_CUSTOMIZATION: 'specs.membership.BlocksLayoutCustomization',
  HEADLESS_SUPPORT: 'specs.membership.HeadlessSupport',
  PLAN_NAME_LINES: 'specs.membership.PlanNameLines',
};
